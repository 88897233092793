const INJECTED_TYPE = {
  NONE: 'NONE',
  UNKNOWN: 'UNKNOWN',
  OPERA: 'OPERA',
  METAMASK: 'METAMASK',
  TRUST: 'TRUST',
  LIQUALITY: 'LIQUALITY',
}

export default INJECTED_TYPE
