import React from 'react'
import cx from 'classnames'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { links } from 'helpers'

import config from 'helpers/externalConfig'

import styles from './Footer.scss'
import CSSModules from 'react-css-modules'

import SocialMenu from './SocialMenu/SocialMenu'
import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import SwitchLang from './SwitchLang/SwitchLang'

import version from 'helpers/version'


const Footer = () => {
  const isFooterDisabled = config.opts.ui.footerDisabled

  return (
    <footer
      className={cx({
        [styles.footer]: true,
        [styles.mobile]: isMobile,
      })}
      data-version-name={version.name}
      data-version-url={version.link}
    >
	 
		
      {!isFooterDisabled && (
        //@ts-ignore
        <WidthContainer styleName="container">
				
         
          {!config.isWidget && <SocialMenu />}

			  {/*<div styleName="version">
            {version.link && version.name ?
              <a href={version.link} target="_blank">
                {version.name}
              </a>
              :
              <span>-</span>
            }
			  </div>*/}
			
			<div id="footerMenu">
			<div styleName="footer-link">
					<a href="/earn/about.php">About</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="/earn/privacy-policy.php">Privacy Policy</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="/earn/term-condition.php">Term &amp; Service</a>                    
                </div>
		  </div>
		  
			{!config.isWidget && (
		  
          <p styleName="swapDescription">
          {/*    <FormattedMessage
                id="FooterSwapDescription_"
				// defaultMessage="Online since 2021. Check our {twitter} and {github} history"
				//defaultMessage="{footerText}"
				defaultMessage=""
                values={{
				 // footerText: <div>Powered by <a href="https://lifttokens.com">LiftTokens</a> &copy; 2022</div>,
				  //footerText: ,
                  github: <a id="twitter" href={links.github} target="_blank">github</a>,
                  twitter: <a id="github" href={links.twitter} target="_blank">twitter</a>,
                }}
		  />*/}
		  
		  <div id="footerDesc"></div>
		  
		  </p>
		
          )}
		  
		  
        </WidthContainer>
      )}
    </footer>
  )
}

export default CSSModules(Footer, styles, { allowMultiple: true })
