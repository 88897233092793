export default {
  zeroxRopsten: 'https://ropsten.api.0x.org',
  oneinch: 'https://api.1inch.exchange/v3.0',
  limitOrders: 'https://limit-orders.1inch.exchange/v1.0',
  horizon: 'https://horizon-testnet.stellar.org',
  blocktrail: 'https://api.blocktrail.com/v1/tBTC',
  bitpay: [
    'https://api.bitcore.io/api/BTC/testnet',
    // 'https://test-insight.bitpay.com/api',
    // 'http://localhost:32250/btc/testnet',
    // 'https://test-insight.swaponline.io/insight-api',
    // 'https://invoices.swaponline.site/proxybitpay-testnet',
  ],
  //ghostscan: 'https://testnet.ghostscan.io/ghost-insight-api',
  ghostscan: '',
  nextscan: 'https://explore.next.exchange/#',
  nextExplorer: 'https://next.swaponline.io/mainnet',
  nextExplorerCustom: 'https://next.swaponline.io/mainnet',
  blockcypher: 'https://api.blockcypher.com/v1/btc/test3',
  etherscan: 'https://api-rinkeby.etherscan.io/api',
  etherscanInvoices: 'https://invoices.swaponline.site/proxyetherscan-testnet',
  etherscan_ApiKey: 'RHHFPNMAZMD6I4ZWBZBF6FA11CMW9AXZNM',
  bscscan: 'https://api-testnet.bscscan.com/api',
  // TODO: replace proxy link
  bscscanInvoices: 'https://invoices.swaponline.site/proxyetherscan-testnet',
  bscscan_ApiKey: 'WI4QEJSV19U3TF2H1DPQ2HR6712HW4MYKJ',
  maticscan: 'https://api-testnet.polygonscan.com/api',
  polygon_ApiKey: '8S2R45ZWG94HI7YK9RCXSK4VCASJ4XVA15',
  faucet: 'https://faucet.swaponline.site/testnet/',
  btc2FAProtected: 'https://2fa.swaponline.site',
  btcPin: 'https://2fapin.swaponline.site',
  invoiceApi: 'https://invoices.swaponline.site',
  multisig: 'https://multisig.swaponline.site',
  txinfo: 'https://txinfo.swaponline.site',
  noxon: 'https://noxon.wpmix.net',
}
