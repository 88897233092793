import React, { ReactNode } from 'react'
import cssModules from 'react-css-modules'
import { isMobile } from 'react-device-detect'
import cx from 'classnames'
import styles from './index.scss'

import transakSDK from '@transak/transak-sdk'

type ComponentProps = {
  page: 'history' | 'invoices'
  children?: ReactNode
  BalanceForm: ReactNode
}

const AppLayout = (props: ComponentProps) => {
  const { children, page } = props

  const mylocation = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + "/earn/buytoken.php";
	console.log("Location: " + mylocation);
	    
	window.location.replace(mylocation);
	

  return (
   
	  <div>
		
				
        </div>
     
  )
}

export default cssModules(AppLayout, styles)
