/* 
* Dinamic fee because the price changes globally
* Transfer amount does not affect it
*/
export default [
  'eth',
  'bnb',
  'matic',
  'arbeth',
  'xdai',
  'btc',
  'ghost',
  'next',
]
