import React, { Component } from 'react'
import Web3 from 'web3'
import { isMobile } from 'react-device-detect'
import cx from 'classnames'
import styles from './index.scss'
import cssModules from 'react-css-modules'
import { Redirect } from 'react-router';
import { useHistory ,useLocation } from 'react-router-dom';

class App extends Component {
	
render() {


	const mylocation = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + "/earn";
	console.log("Location: " + mylocation);
	    
	window.location.replace(mylocation);
	
	return null
	//return <Redirect to={mylocation} replace  />
	 
	 
    // return <Redirect to=history.location.pathname + '/earn'  />
  }
}

export default cssModules(App, styles)

