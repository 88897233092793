import { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import JsonLd from './JsonLd'
import seo, { getSeoPage, getUrl } from 'helpers/seo'

import { isMobile } from 'react-device-detect'

import axios from 'axios'

export default class Seo extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
	  data: null,
	  maintitle: null,
	  loading: true,
	  serverResponse: '',
	  serverLicense: '',
	  error: "",	  
      seoPage: getSeoPage(props.location.pathname)
    }
  }
  


componentWillMount(){
     this.getData();
	 this.getLicense();
	 //this.getBuyToken();
  }
  
  async getLicense(){
   /*const res = await axios.get('https://app.lifttokens.com/api/checklicense?domain=' + window.location.hostname + '', 
    {
            headers : {
				'Access-Control-Allow-Origin' : '*',
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            withCredentials: false,
            });*/
   
  const res = await axios({
    method: 'get',
    url: 'https://app.lifttokens.com/api/checklicense?domain=' + window.location.hostname,
    withCredentials: false,	
    headers : {
				'Access-Control-Allow-Origin' : '*',
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            },
  });
  
   const { data } = await res;
   this.setState({serverLicense: data})
   const license = this.state.serverLicense.license
   console.log("LIC: " + this.state.serverLicense.license);   
   console.log("DOM: " + this.state.serverLicense.domain);   
   
   if( license != 'valid')
   {
	   // window.location.href = "https://app.lifttokens.com/renew";
		if( license != undefined )
		   {
			//console.log('redirect');
			window.location.href = "https://app.lifttokens.com/renew";
		}
		else { console.log('undefined condition'); }
   }
   
   
 }
 
  async getData(){
   var url = 'https://app.lifttokens.com/api/settings?domain=' + window.location.hostname    
   // const url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/settings.php'
   //const url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/settings.html'
   //const res = await axios.get('https://dev.lifttokens.com:8443/dev/title.html');
   const res = await axios.get(url);
   const { data } = await res;
   this.setState({serverResponse: data})
   var name = this.state.serverResponse.name;
   console.log("TA: " + this.state.serverResponse.title);
   //document.title = this.state.serverResponse.title;
   document.title = name + " - " + this.state.serverResponse.title;
  
   const adsBottom = document.getElementById("footerAds") as HTMLDivElement
   adsBottom.innerHTML = this.state.serverResponse.ads_bottomspace;
   
   const adsTop = document.getElementById("topAds") as HTMLDivElement
   adsTop.innerHTML = this.state.serverResponse.ads_topspace;
   
   
   if( !isMobile )
   {
   const sideSmallRight = document.getElementById("sideSmallRight") as HTMLDivElement
   sideSmallRight.innerHTML = this.state.serverResponse.ads_rightspace;
   
   const sideSmallLeft = document.getElementById("sideSmallLeft") as HTMLDivElement
   sideSmallLeft.innerHTML = this.state.serverResponse.ads_leftspace;
   }
   
   document.body.style.setProperty('--color-btn-link', this.state.serverResponse.third_font_color);	   
   document.body.style.setProperty('--color-brand', this.state.serverResponse.color);	
   document.body.style.setProperty('--color', this.state.serverResponse.main_font_color);	
   document.body.style.setProperty('--color-indicating', this.state.serverResponse.second_font_color);	   
   document.body.style.setProperty('--color-notice', this.state.serverResponse.second_font_color);	
   document.body.style.setProperty('--color-page-background', this.state.serverResponse.pagebg);	
   document.body.style.setProperty('--color-background-elements', this.state.serverResponse.elementbg);	
   
   
    var newScript = document.createElement( "script" );
	newScript.type = 'text/javascript';
	var scriptContent = document.createTextNode( this.state.serverResponse.storejs ); 
	newScript.appendChild( scriptContent ); 

   //document.head.innerHTML = this.state.serverResponse.storejs + document.head.innerHTML;
   
   //document.write(this.state.serverResponse.storejs);
   
  
		
   if ( this.state.serverResponse.twitter != "")
   {
		const tw = document.getElementById("twitter1") as HTMLAnchorElement
		console.log("Tw: " + this.state.serverResponse.twitter);
		tw.href = this.state.serverResponse.twitter		
   }
   else
   {
		const twCont = document.getElementById("twCont") as HTMLDivElement
		twCont.style.display = 'none';
   }
   
   if ( this.state.serverResponse.whatsapp != "")
   {
		const wa = document.getElementById("whatsapp1") as HTMLAnchorElement	
		wa.href = this.state.serverResponse.whatsapp
   }
   else
   {
	    const waCont = document.getElementById("waCont") as HTMLDivElement
		waCont.style.display = 'none';
   }
   
   if ( this.state.serverResponse.facebook != "")
   {
		const fb = document.getElementById("facebook1") as HTMLAnchorElement	
		fb.href = this.state.serverResponse.facebook
   }
   else
   {
	    const fbCont = document.getElementById("fbCont") as HTMLDivElement
		fbCont.style.display = 'none';
   }
   
   if ( this.state.serverResponse.telegram != "")
   {
		const telegram = document.getElementById("telegram1") as HTMLAnchorElement	
		telegram.href = this.state.serverResponse.telegram
   }
   else
   {
	    const teCont = document.getElementById("teCont") as HTMLDivElement
		teCont.style.display = 'none';
   }
   
   if ( this.state.serverResponse.discord != "")
   {
		const discord = document.getElementById("discord1") as HTMLAnchorElement	
		discord.href = this.state.serverResponse.discord
   }
   else
   {
	    const diCont = document.getElementById("diCont") as HTMLDivElement
		diCont.style.display = 'none';
   }
   
   if ( this.state.serverResponse.youtube != "")
   {
		const youtube = document.getElementById("youtube1") as HTMLAnchorElement	
		
		youtube.href = this.state.serverResponse.youtube
   }
   else
   {
	    const ybCont = document.getElementById("ybCont") as HTMLDivElement
		ybCont.style.display = 'none';
   }
   
   if ( this.state.serverResponse.reddit != "")
   {
		const reddit = document.getElementById("reddit1") as HTMLAnchorElement	
		reddit.href = this.state.serverResponse.reddit
   }
   else
   {
	    const rdCont = document.getElementById("rdCont") as HTMLDivElement
		rdCont.style.display = 'none';
   }
   
  
   const footerDesc = document.getElementById("footerDesc") as HTMLDivElement
   //footerDesc.innerHTML = '&copy; 2022. All rights reserved. ' + this.state.serverResponse.title
   footerDesc.innerHTML = this.state.serverResponse.footer_note;
   
   console.log("Host" + window.location.protocol + ' + ' + window.location.hostname + ' + ' + window.location.port)
   
   const logoSpace = document.getElementById("logoSpace") as HTMLImageElement
   //logoSpace.src = '/uploads/' + this.state.serverResponse.logo
	logoSpace.src = this.state.serverResponse.logo
   
   
   //token
   const apistage = "https://staging-global.transak.com/"
   const apiprod = "https://global.transak.com/"
   
   const tags = '&fiatAmount=50&isDisableCrypto=true&isFeeCalculationHidden=true&hideMenu=true&themeColor='
   const apikey = this.state.serverResponse.apikey
   const apienv = this.state.serverResponse.apienv
   const style = 'display: block; width: 100%; min-height: 720px; border: 0px; overflow:hidden; overflow-x:hidden; overflow-y:hidden;'   
   const styleMob = 'display: inline-block; float: left; width: 100%; min-height: 720px; border: 0px; overflow:hidden; overflow-x:hidden; overflow-y:hidden;'
   const extraParam = 'scrolling="no"'
   const color = this.state.serverResponse.color
   
   const colorTheme = color.substring(1);
   console.log("Frame: " + this.state.serverResponse.apienv);   
   
   var apiLink = ''
   if(apienv == 'staging')
   {
	   apiLink = apistage
   }
   else
   {
	   apiLink = apiprod
   }
   console.log('Stage: ' + apiLink)
   
   if( !isMobile )
   {
   const buyTokenFrame = document.getElementById("buyTokenFrame") as HTMLDivElement

   console.log("Env: " + apienv);
   
   const tokenFrame = '<iframe ' + extraParam + ' src="' + apiLink + '?apiKey=' + apikey + tags + colorTheme + '" style="' + style + '"></iframe>'
   
   console.log("A: " + tokenFrame);
	   if(buyTokenFrame)
	   {
	   buyTokenFrame.innerHTML = tokenFrame
	   }
   }
   else
	{
   const buyTokenFrameMobile = document.getElementById("buyTokenFrameMobile") as HTMLDivElement
   
   const tokenFrameMobile = '<iframe ' + extraParam + ' src="' + apiLink + '?apiKey=' + apikey + tags + colorTheme + '" style="' + styleMob + '"></iframe>'
   console.log("A: " + tokenFrameMobile);
   buyTokenFrameMobile.innerHTML = tokenFrameMobile
	}
   
 }

 /* async getBuyToken(){
   const res = await axios.get('https://dev.lifttokens.com:8443/api/buytoken.html');
   const { data } = await res;
   this.setState({serverToken: data})
   const token = this.state.serverToken.link
   const tags = this.state.serverToken.tags
   const apikey = this.state.serverToken.apikey
   const style = this.state.serverToken.style
   const styleMob = this.state.serverToken.styleMob
   const extraParam = this.state.serverToken.extraParam
   const color = this.state.serverResponse.color
   
   const colorTheme = color.substring(1);
   console.log("Frame: " + this.state.serverToken.link);   
   
   if( !isMobile )
   {
   const buyTokenFrame = document.getElementById("buyTokenFrame") as HTMLDivElement
   
   const tokenFrame = '<iframe ' + extraParam + ' src="' + token + apikey + tags + colorTheme + '" style="' + style + '"></iframe>'
   console.log("A: " + tokenFrame);
   buyTokenFrame.innerHTML = tokenFrame
   }
   else
	{
   const buyTokenFrameMobile = document.getElementById("buyTokenFrameMobile") as HTMLDivElement
   
   const tokenFrameMobile = '<iframe ' + extraParam + ' src="' +  token + apikey + tags + colorTheme + '" style="' + styleMob + '"></iframe>'
   console.log("A: " + tokenFrameMobile);
   buyTokenFrameMobile.innerHTML = tokenFrameMobile
	}
   
 }*/
 
  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps
    const { location } = this.props

	 this.getData();
	 
    if (location.pathname !== prevLocation.pathname) {
      const seoPage = getSeoPage(location.pathname)

      this.setState(() => ({
        seoPage,
      }))
    }
  }

  shouldComponentUpdate(nextProps) {
    return this.props.location.pathname !== nextProps.location.pathname
  }

  render() {
    const { seoPage, data, serverResponse } = this.state

	console.log("Test: " + this.state.serverResponse);
	console.log("Test 2: " + serverResponse);

    if (!seoPage) {
      return null
    }

    const { uri, title, description } = seoPage
    const url = getUrl(uri)

    return (
      <DocumentMeta
        title={title}
        description={description}
        canonical={url}
        meta={{
          property: {
            'og:title': title,
            'og:description': description,
            'og:url': url,
            'og:image': seo.config.logo,
          },
        }}
      >
        <JsonLd uri={uri} title={title} description={description} />
      </DocumentMeta>
    )
  }
}
