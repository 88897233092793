import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './SocialMenu.scss'
import links from 'helpers/links'


const nav = [
  { links: links.medium,      icon: 'fa-medium-m' },
  { links: links.twitter,     icon: 'fa-twitter' },
  { links: links.facebook,    icon: 'fa-facebook' },
  { links: links.github,    icon: 'fa-github' },
  { links: links.telegram,    icon: 'fa-telegram-plane' },
  { links: links.bitcointalk, icon: 'fa-btc' },
  { links: links.discord,     icon: 'fab fa-discord' },
  { links: links.youtube,     icon: 'fab fa-youtube' },
  { links: links.reddit,      icon: 'fab fa-reddit' },
]

const SocialMenu = () => (
<ul styleName="social-menu">


{/*    {
      nav.filter(item => item.links !== '#').map((item, index) => (
        <li key={index}>
          <a href={item.links} target="_blank" rel="noopener noreferrer">
            <i styleName="icon" className={`fab ${item.icon}`} />
          </a>
        </li>
      ))
    }*/}
	
	  <li id="twCont"><a id="twitter1" target="_blank" rel="noopener noreferrer"><i styleName="icon" className="fab fa-twitter"></i></a></li>
	  <li id="waCont"><a href="#" id="whatsapp1" target="_blank" rel="noopener noreferrer"><i styleName="icon" className="fab fa-whatsapp"></i></a></li>
  <li id="fbCont"><a href="#" id="facebook1" target="_blank" rel="noopener noreferrer"><i styleName="icon" className="fab fa-facebook"></i></a></li>
  <li id="teCont"><a href="#" id="telegram1" target="_blank" rel="noopener noreferrer"><i styleName="icon" className="fab fa-telegram-plane"></i></a></li>
  <li id="diCont"><a href="#" id="discord1" target="_blank" rel="noopener noreferrer"><i styleName="icon" className="fab fa-discord"></i></a></li>
  <li id="ybCont"><a href="#" id="youtube1" target="_blank" rel="noopener noreferrer"><i styleName="icon" className="fab fa-youtube"></i></a></li>
  <li id="rdCont"><a href="#" id="reddit1" target="_blank" rel="noopener noreferrer"><i  styleName="icon" className="fab fab fa-reddit"></i></a></li>
</ul>


)

export default CSSModules(SocialMenu, styles)
