export default {
  eth: 0.0006,
  bnb: 0.0006,
  matic: 0.0006,
  arbeth: 0.0006,
  xdai: 0.0006,
  btc: 0.0002,
  jot: 1,
  usdt: 0,
  erc: 1,
}
